export const BG_COVER = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20cover%20portrait.jpg?updatedAt=1704654356360`;
export const BG_COUNTING = `https://ik.imagekit.io/farahoktarina/christineandirwin/Counting%20days.jpg?updatedAt=1704654356316`;
export const BG_FOOTER = `https://ik.imagekit.io/farahoktarina/christineandirwin/Footer.jpg?updatedAt=1704654356363`;
export const BG_LOVESTORY = `https://user-images.githubusercontent.com/10141928/149857523-805f43ad-0c1b-4300-b72c-3f8e8d37a1f8.jpg`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahoktarina/christineandirwin/bg-desktop.jpg?updatedAt=1704654359874`;

// images welcome section
export const IMG_LAMPION = `https://user-images.githubusercontent.com/10141928/150158769-8c451a18-4b83-46fe-97d9-3fc8f87f7e06.png`;
export const IMG_WELCOME_1 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20welcoming.jpg?updatedAt=1704654360336`;
export const IMG_WELCOME_2 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20welcoming(1).jpg?updatedAt=1704654356233`;
export const IMG_WELCOME_3 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20welcoming(2).jpg?updatedAt=1704654356164`;
export const IMG_WELCOME_4 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20welcoming(3).jpg?updatedAt=1704654356145`;
export const IMG_WELCOME_5 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20welcoming(4).jpg?updatedAt=1704654356246`;

// couple image
export const IMG_MAN = `https://ik.imagekit.io/farahoktarina/christineandirwin/Photo%20groom.jpg?updatedAt=1704654360396`;
export const IMG_GIRL = `https://ik.imagekit.io/farahoktarina/christineandirwin/Photo%20bride.jpg?updatedAt=1704654359644`;
export const IMG_PHOTO_COVER = `https://user-images.githubusercontent.com/10141928/150639548-4e4b612b-35c4-4fda-88d7-fa3cdd97295d.png`;
export const IMG_LOGO_WEDDING = `https://user-images.githubusercontent.com/10141928/150645258-2dddbcd4-a473-4d90-b2f5-825d0fe1737e.png`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/149857534-17748784-0c8a-451a-80e0-941e2798927f.jpg`;

// video assets
export const VIDEO_COVER = `https://user-images.githubusercontent.com/10141928/150642624-d39b2ace-41e7-4464-a1ed-1cadc519503a.mp4`;

// image photo gallery cover
export const IMG_PHOTO_1 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20cover%20gallery%20luar.jpg?updatedAt=1704654356324`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20cover%20gallery%20luar(1).jpg?updatedAt=1704654359818`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20cover%20gallery%20luar(2).jpg?updatedAt=1704654356241`;
export const IMG_COVER_GALLERY = `https://ik.imagekit.io/farahoktarina/christineandirwin/Foto%20cover%20gallery%20dalam(1).jpg?updatedAt=1704654356287`;

// image icons
export const IMG_ICON_HAND = `https://user-images.githubusercontent.com/10141928/150661877-d975b35b-3e55-4333-b216-2f9df6ca2584.png`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
