import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'iloveyou';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Christine Fang, B.A';
export const GIRL_NAME_SHORT = 'Christine';
export const GIRL_FATHER_NAME = `Jimmy`;
export const GIRL_MOTHER_NAME = `Julia`;
export const GIRL_PARENT_NAME = {
  en: `The daughter of Mr. ${GIRL_FATHER_NAME}<br /> and Mrs. ${GIRL_MOTHER_NAME}`,
  id: `Putri dari Bapak ${GIRL_FATHER_NAME}<br /> dan Ibu ${GIRL_MOTHER_NAME}`,
};
// -> boy section
export const BOY_NAME = 'Dr. Irwin Ivandi Puc';
export const BOY_NAME_SHORT = 'Irwin';
export const BOY_FATHER_NAME = `Edgar Orlando Puc`;
export const BOY_MOTHER_NAME = `Lily Puc`;
export const BOY_PARENT_NAME = {
  en: `The son of Mr. ${BOY_FATHER_NAME}<br /> and Mrs. ${BOY_MOTHER_NAME}`,
  id: `Putra dari Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`,
};
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'irwin.puc';
export const IG_GIRL = 'christinefangg';

//Instagram Filter
export const IG_FILTER = 'https://www.instagram.com/ar/1099919341293244/';
export const IMG_IG_FILTER =
  'https://ik.imagekit.io/farahoktarina/christineandirwin/COVER%20IRWIN%20CHRISTINE.jpg?updatedAt=1705385080631';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = 'https://ik.imagekit.io/farahoktarina/christineandirwin/seo-belize_L4DpoFyiy?updatedAt=1704657035159';
export const SEO_URL = 'https://invitato.net/irwinandchristine';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.net`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1708772400;
export const EPOCH_RESEPSI_START = 1708772400;
export const EPOCH_END_EVENT = 1708779600;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-02-24');
export const DATE_RESEPSI = new Date('2024-02-24');

export const WEDDING_AKAD_TIME = '5:00 PM';
export const WEDDING_AKAD_LOC_NAME = 'Mopan Techical Auditorium';
export const WEDDING_AKAD_LOC_ROAD = `Said Musa Street, Benque Viejo Del Carmen, Cayo District, Belize, Central America`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '5:00 PM';
export const WEDDING_RESEPSI_LOC_NAME = `Mopan Technical Auditorium`;
export const WEDDING_RESEPSI_LOC_ROAD = `Said Musa Street, Benque Viejo Del Carmen, Cayo District, Belize, Central America`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_DATE = dayjs(DATE_RESEPSI).locale(DEFAULT_LANGUAGE).format('DD • MM • YYYY');
/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://irwinandchristine.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_RESEPSI_LOC_NAME;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/818ykUtEGTps63RZ6`;
export const GOOGLE_MAPS_ADDRESS = WEDDING_RESEPSI_LOC_NAME;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `elijah woods - 24/7 365`;
export const SOUND_URL = 'https://youtu.be/-h_yBTriuaY?si=Sps23nEP8aKk48at';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/WEC5RezD5jU';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/WEC5RezD5jU';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
